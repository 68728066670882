jQuery(function ($) {
  $(".accordion-group").find("a").on("click", function (e) {
    e.preventDefault();

    // グループ
    var group = $(this).parent().parent();
    // pタグ
    var thisP = $(this).parent();
    // ボディ
    var body = group.find(".accordion-body");
    if (body.hasClass("open")) {
      body.removeClass("open");
    } else {
      body.addClass("open");
    }
    if (thisP.hasClass("active")) {
      thisP.removeClass("active");
    } else {
      thisP.addClass("active");
    }
  });
});